import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Using NDS in Operational Solution Core`}</h1>
    <p>{`The Design System is primarily designed to be used in our newer applications and modules. These have large fonts and a more modern look and feel. The design system can still be used in our legacy applications, but there are some differences and things to be aware of.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1024px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/9d2f11da192f096e0cd8296c56d2a965/9b29b/nds-in-ops-core.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQoz42SzW8SQRjG+YetmrRetHy4QCvsLmyXxRVBYIHGRhuPHj158yIm0AQ8tEkj9gO1yX6W8jMzFLJVTJzkySYz7/Ob5913Eo9yFnFtZS0epsvcT5bYSOrce6Kysa2xqVTYyi3PdB6kSrJWem69m4pJQjGaxJU1WuSrbXY0m3zBRq29pmj3KNr7qPY+xec9qV3LQTFaMW+LTKlBIq03iCupvkR90WV4NGYwGHF8OuHsYsrk/JJvZ5d8P59ycjqhUO3I2j+9iUzpFXGltDpm8w1Xrs8vN+THlYfrhQRBRBTNuLmZE4QRRuNAAoRHwKR3LVBvUK4fcDH9iesFeJ5PEIa4rsdsNkOsKLrGah2SVOt3gGmtvi5hQ94uIL7vEwQB8/lcSgA9P8DzfSrNtytgHLq2Zat9iBuAf80/l6j5L6A4UO0e7z4MeP9xxOfBmC9HX+kPhcb0ByM+9YeyRnTzF3A5oeXm09t/kdEXWk5PJN8u1nhcsEmqNZTyXdDahKtp6YtWhClvtti1OjyrdtmpOPJbqHbl3lpg3nRYqeKQ22uT0hbPIbfnkDXaKIZD1uzI9CK1uEg85oWvTZzxG/9HguULQyr3AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "nds in ops core",
          "title": "nds in ops core",
          "src": "/static/9d2f11da192f096e0cd8296c56d2a965/2bef9/nds-in-ops-core.png",
          "srcSet": ["/static/9d2f11da192f096e0cd8296c56d2a965/6f3f2/nds-in-ops-core.png 256w", "/static/9d2f11da192f096e0cd8296c56d2a965/01e7c/nds-in-ops-core.png 512w", "/static/9d2f11da192f096e0cd8296c56d2a965/2bef9/nds-in-ops-core.png 1024w", "/static/9d2f11da192f096e0cd8296c56d2a965/71c1d/nds-in-ops-core.png 1536w", "/static/9d2f11da192f096e0cd8296c56d2a965/a878e/nds-in-ops-core.png 2048w", "/static/9d2f11da192f096e0cd8296c56d2a965/9b29b/nds-in-ops-core.png 3840w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h2>{`Font Size`}</h2>
    <p>{`The default body font size in NDS is 16px. Due to layout limitations in Ops Core, we can’t use a default font that large. If you use an NDS component inside of Ops Core, the NDSProvider needs to override the “medium” font size will from 16px to 14px. An example of this being done can be found `}<a parentName="p" {...{
        "href": "https://github.com/nulogy/PackManager/blob/main/client_webpack/warehouse_management/src/lib/NDSProvider.tsx"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Style Overrides`}</h2>
    <p>{`There are cases where you may be modernizing an older part of the application and want to use our new React-based technologies but still keep the UI looking the same. Since our components are built using styled components, they can be overriden using our theme and standard CSS references. An example of this can be found `}<a parentName="p" {...{
        "href": "https://github.com/nulogy/PackManager/blob/main/packages/components/src/LegacyPagination.tsx"
      }}>{`here`}</a>{`.`}</p>
    <Alert mdxType="Alert">
  We don’t want to propogate this pattern too much, as we’d rather update the
  look-and-feel of older modules. Currently, we only have legacy-styled Tables
  and Pagination. If you’re about to make drastic style changes to a component,
  please reach out for a consultation with Design Ops first.
    </Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      